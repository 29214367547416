import * as Yup from 'yup';

export const DeliverySchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  phone: Yup.string()
    .matches(/^(\+64|0)\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d?\s*\d?$/, 'Phone number should start with +64 or 0')
    .required('Required'),
  deliveryNote: Yup.string().required(),
  deliveryFee: Yup.boolean().oneOf([true],'Required'),
});
