import styled from 'styled-components';
import theme from '../../feachers/theme';

type LabelTypes = {
  labelColor: string;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxInput = styled.input`
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  accent-color: #787d2f;
  cursor: pointer;
  @media screen and ${theme.breakpoints.mobile} {
    flex-shrink: 0;
  }
`;

export const Label = styled.label<LabelTypes>`
  margin-left: 25px;
  font-size: 17px;
  font-family: Meloso;
  font-weight: 500;
  cursor: pointer;
  color: ${({ labelColor }) => labelColor};
`;
